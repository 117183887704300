<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card>
      <v-toolbar small color="white" flat>
        <v-toolbar-title>
          Saldo:{{ (totalesFactura.total - total_pagado).toFixed(2) }}
        </v-toolbar-title>

        <v-divider class="mx-4" inset vertical> </v-divider>
        <v-spacer></v-spacer>

        <v-btn color="primary" small class="ma-2 white--text" v-if="cabecera.status_id == 'INVOICE_IN_PROCESS'" 
        @click="completarInvoice()">
          <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>Completar
        </v-btn>

        <v-menu offset-y v-if="cabecera.status_id=='INVOICE_READY' || cabecera.status_id=='INVOICE_PAID'">
          <template v-slot:activator="{ on, props }">
            <v-btn  small v-bind="props" v-on="on" color="blue" class="mx-4" dark>
              <v-icon  left>mdi-cash-refund</v-icon>Nota de credito
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in getMenuNotaCredito" :key="index" >
                <template><v-btn @click="handleFunctionCall(item.accion)" small text> <v-icon>{{ item.icon }}</v-icon>                
                {{ item.title }}  </v-btn></template>
            </v-list-item>
          </v-list>
        </v-menu>
        

        <v-menu offset-y  v-if="cabecera.status_id != 'INVOICE_IN_PROCESS'">
          <template v-slot:activator="{ on, props }">
            <v-btn color="blue" dark small v-bind="props" v-on="on">
              <v-icon left>mdi-file-download</v-icon>
              Comprobante Electrónico
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, index) in getMenuComprobante" :key="index" :href="item.accion">
              <v-list-item-title><v-icon>{{ item.icon }}</v-icon>
                {{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y v-if="cabecera.status_id == 'INVOICE_READY'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="(cabecera.parent_type_id =
              'SALES_INVOICE' &&
              (cabecera.invoice_type_id == 'NOTA_DEBITO_INT_VTA' ||
                cabecera.invoice_type_id == 'SALES_INVOICE' ||
                cabecera.tinvoice_type_id == 'NOTA_DEBITO'))
              " class="ma-2 white--text" color="blue" dark v-bind="attrs" v-on="on" small>
              <v-icon>mdi-plus</v-icon>
              Ingresar pago
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in menuPagos" :key="index" @click="ingPago(index)">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn color="blue" small class="ma-2 white--text" @click="solicitarReembolso" v-if="cabecera.invoice_type_id == 'CUST_RTN_INVOICE' &&
          cabecera.status_id == 'INVOICE_READY' &&
          (totalesFactura.total - total_pagado).toFixed(2) > 0 &&
          puedoSolicitarReembolsoCliente
          ">
          <v-icon left>mdi-cash-refund</v-icon>
          Solicitar reembolso
        </v-btn>

        <v-btn v-if="existe_pago_cheque" small class="ma-2 white--text" color="blue" @click="solicitarDevCheque">Solicitar
          Devolucion de cheque
        </v-btn>
      </v-toolbar>

      <v-card class="pl-4 pr-4 pt-6">
        <v-row>
          <v-col md="5" sm="5">
            <v-row>
              <v-col sm="12" md="12" class="pt-0 pb-0">
                <v-text-field label="Cliente*" required type="text" small readonly
                  v-model="cabecera.participante"></v-text-field>
              </v-col>

              <v-col sm="6" md="6" class="pt-0 pb-0">
                <v-text-field label="Estado*" required type="text" small readonly
                  v-model="cabecera.estado"></v-text-field>
              </v-col>

              <v-col sm="6" md="6" class="pt-0 pb-0">
                <v-text-field label="Documento origen" required v-if="cabecera.invto_numero != null" type="text" small
                  readonly v-model="cabecera.invto_tipo + ' : ' + cabecera.invto_numero"></v-text-field>

                <v-btn v-if="cabecera.invto_numero == null" text title="Ver cotización de origen" target="_blank"
                  :to="`/cotizacion/${cabecera.cotizacionFa}`">
                  <v-icon color="info">mdi-eye</v-icon>
                  {{ cabecera.cotizacionFa }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="6" md="6" class="pt-0 pb-0">
                <v-text-field label="Fecha de emisión*" required type="text" small readonly
                  v-model="cabecera.inv_fecha"></v-text-field>
              </v-col>

              <v-col sm="6" md="6" class="pt-0 pb-0">
                <v-text-field label="Fecha de vencimiento " required type="text" small readonly
                  v-model="cabecera.due_date"></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="7" sm="7" class="pt-0 pb-0">
            <v-data-table :headers="headPagos" :items="pagos" :items-per-page="100" hide-default-footer
              class="elevation-1">
              <template v-slot:item.effective_date="{ item }">
                {{ item.effective_date.substr(0, 10) }}
              </template>

              <template v-slot:item.opcion="{ item }">
                <v-btn text title="Borrar apliación del pago" @click="borrarAplicacionPago(item)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.payment_id="{ item }">
                <v-btn v-if="item.payment_type_id != 'CRUCE_NC'" text title="Haga click para abrir pago" target="_blank"
                  :to="`/ver-pago/${item.payment_id}`">
                  <v-icon color="info">mdi-eye</v-icon>
                  {{ item.payment_id }}
                </v-btn>

                <v-btn v-if="item.payment_type_id == 'CRUCE_NC'" text title="Haga click para abrir el documento"
                  target="_blank" :to="`/factura-venta/${item.fa_rel.invoice_id}`">
                  <v-icon color="info">mdi-eye</v-icon>
                  {{ item.fa_rel.invoice_id }}
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" sm="12">
            <v-data-table v-if="cabecera.status_id!='INVOICE_IN_PROCESS'" :headers="headItems" :items="itemsFactura" :items-per-page="100" hide-default-footer
              class="elevation-1">
            </v-data-table>
            

            
            <v-simple-table dense v-if="cabecera.status_id=='INVOICE_IN_PROCESS'" >
                    <template v-slot:default>
                        <thead>
                            <tr color="primary">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                                <th>ITEM</th>
                                <th>DESCRIPCIÓN</th>
                                <th>CANTIDAD</th>
                                <th>PRECIO</th>
                                <th>DESCUENTO</th>
                                <th>%IVA</th>
                                <th>IVA</th>
                                <th>TOTAL</th>
                                <th v-if="!noEdit">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(si, x) in itemsFactura                                                                                                                                        " :key=x>
                                <td style="width:300px">
                                    <v-autocomplete 
                                        v-model=si.invoiceItemTypeId placeholder="Item de la factura"
                                        :items="tiposItems" 
                                        :rules=required item-value="invoice_item_type_id"
                                        item-text="description" 
                                        :readonly="noEdit"></v-autocomplete>
                                </td>
                                <td style="width:400px">
                                    <v-text-field v-model="si.description" placeholder="Descripción" :rules=required
                                        style="font-size:13px" :readonly="noEdit"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field v-model="si.cantidad" placeholder="Cantidad" type="number" min="0"
                                        style="font-size:13px" :rules="requiredMin" @keyup="calculateTotal"
                                        @change="calculateTotal" :readonly="noEdit"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field v-model="si.valorUnitario" placeholder="precio" type="number" min="0"
                                        style="font-size:13px" @keyup="calculateTotal" @change="calculateTotal"
                                        :rules="requiredMin" :readonly="noEdit"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field v-model="si.descuento" placeholder="descuento" type="number" min="0"
                                        style="font-size:13px" @keyup="calculateTotal" @change="calculateTotal"
                                        :rules="requiredMin" :readonly="noEdit"></v-text-field>
                                </td>
                                <td> {{si.impId}}
                                    <v-autocomplete v-model=si.impId placeholder="Iva" :items=taxTypes
                                        :rules=required item-value="tax_authority_rate_seq_id" item-text="tax_percentage"
                                        @change="calculateTotal" :readonly="noEdit"></v-autocomplete>
                                </td>
                                <td>${{ si.valor_iva }}</td>
                                <td>${{ si.total }}</td>
                                <td style="width:100px" v-if="!noEdit">                                  
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" color="primary" @click="storeInvoceItem(x)">
                                                mdi-floppy
                                            </v-icon>
                                        </template>
                                        <span>Guardar item</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" color="red" @click="deleteInvoiceItem(si)">
                                                mdi-delete-forever
                                            </v-icon>
                                        </template>
                                        <span>Eliminar item </span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="8" sm="8" class="pt-0 pb-0"> </v-col>
          <v-col md="4" sm="4" class="pt-0 pb-0">
            <v-col md="12" sm="12" class="pl-12 pt-10 ">
              <v-row>
                <v-col class="b-1 pt-2 pb-1" style="border-bottom: 1px solid #cecece">Sub total</v-col>
                <v-col align="right" class="pt-2 pb-1" style="border-bottom: 1px solid #cecece">{{ totalesFactura.subTotal
                }}</v-col>
              </v-row>

              <v-row>
                <v-col class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">Descuento</v-col>
                <v-col align="right" class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">{{
                  totalesFactura.descuento }}</v-col>
              </v-row>
              <v-row>
                <v-col class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">Sub total IVA 12%</v-col>
                <v-col align="right" class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">{{
                  totalesFactura.subTotalConIva }}</v-col>
              </v-row>
              <v-row>
                <v-col class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">Sub total IVA 0%</v-col>
                <v-col align="right" class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">{{
                  totalesFactura.subTotalSinIva }}</v-col>
              </v-row>
              <v-row>
                <v-col class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">IVA</v-col>
                <v-col align="right" class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">{{ totalesFactura.totalIVA
                }}</v-col>
              </v-row>

              <v-row>
                <v-col class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">Total</v-col>
                <v-col align="right" class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">{{ totalesFactura.total
                }}</v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12">
            <v-expansion-panels accordion v-model="panel">
              <v-expansion-panel class="px-0 pb-0">
                <v-expansion-panel-header color="blue-grey lighten-5">
                  ASIENTOS CONTABLES
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <AccountingEntries :asientos="asientos" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card>
    </v-card>

    <!-- DIALOGO DE SOLICITUD DE CHEQUE -->
    <v-dialog v-model="solChequeDev" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Solicitar devolucion de cheque</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formSolChequeDev">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="10" md="10">
                  <v-select v-model="datosDevCheque.fin_account_id" small item-text="fin_account_name"
                    item-value="fin_account_id" :items="cuentasBancarias" label="Cuenta bancaria"
                    :rules="finAccountRule"></v-select>
                </v-col>

                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field v-model="datosDevCheque.ref1" label="Referencia devolucion*" type="text"
                    :append-icon="resRefCheque" :rules="[refRule, refExiste]" append-outer-icon="mdi-magnify" small
                    :suffix="valorChequeEc" @click:append-outer="
                      buscarRefBanco('001316', datosDevCheque.ref1)
                      ">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-text-field v-model="datosDevCheque.refCosto" label="Referencia costo" type="text"
                    append-icon="mdi-magnify" small :suffix="costoChequeEc" :rules="[validarRefCosto]" @click:append="
                      buscarRefBanco('098426', datosDevCheque.refCosto)
                      "></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*campos obligatorios</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="solChequeDev = false">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" solid class="white--text" @click="enviarSolicitudCheque()">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FIN DIALOGO SOLICITUD DE CHEQUE -->

    <!-- DIALOGO DE INGRESO DE PAGO -->
    <v-dialog v-model="dialogPago" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Ingresar Pago</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formPago">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="12" md="12">
                  <v-select 
                    v-model="datosPago.payment_method_type_id" 
                    small 
                    item-text="description"
                    item-value="payment_method_type_id" 
                    :items="formasPagoPermiso" label="Forma de pago"
                    :rules="finAccountRule" 
                    @change="cambioPmt()"
                  ></v-select>

                  <v-select v-if="datosPago.payment_method_type_id == 'EFT_ACCOUNT'" v-model="datosPago.fin_account_id"
                    small item-text="fin_account_name" item-value="fin_account_id" :items="cuentasBancarias"
                    label="Cuenta bancaria" :rules="[validarCuentaBancaria]"></v-select>

                  <v-select v-if="datosPago.payment_method_type_id == 'CREDIT_CARD' ||
                    datosPago.payment_method_type_id == 'CREDIT_CARD_DIF' ||
                    datosPago.payment_method_type_id ==
                    'CREDIT_CARD_DIF_INT' ||
                    datosPago.payment_method_type_id == 'DEBIT_CARD'
                    " v-model="datosPago.payment_method_id" small item-text="card_type" item-value="payment_method_id"
                    :items="tiposTarjetaMetodo" label="Tipo de tarjeta" :rules="[validarTipoTarjeta]"></v-select>
                </v-col>

                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field v-model="datosPago.effective_date" label="Fecha" type="date" small
                    :rules="[validarEffectiveDate]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field v-model="datosPago.payment_ref_num" label="Referencia" type="text" small
                    :rules="[validarRefPago]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-text-field v-model="datosPago.monto" label="Monto pagado" type="text" small
                    :rules="[validarMontoPago]"></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="['PLACE_TO_PAY_CC','PLACE_TO_PAY_DC'].includes(datosPago.payment_method_type_id)">
                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-img
                          v-bind="attrs"
                          v-on="on"
                          src="../../assets/images/placetopay-logo.png"
                          width="70px"
                          height="70px"
                        />
                    </template>
                    <!-- <span>Se generará link de pago de PlaceToPay al guardar el pago</span> -->
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*campos obligatorios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="blue darken-1"
            small 
            text
            @click="dialogPago = false" 
            class="px-4 mb-4"
          >
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn 
            :loading="btnGuardarLoading" 
            color="blue darken-1" 
            solid 
            small 
            class="white--text mb-4" 
            @click="ingresarPago()"
          >
            <v-icon>mdi-content-save</v-icon>Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FIN DIALOGO INGRESO DE PAGO -->

    <!-- DIALOGO DE INGRESO DE RETENCION -->
    <v-dialog v-model="dialogRetencion" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Ingresar Retención</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formRetencion">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="12" md="12">
                  <v-select v-model="datosPago.payment_method_type_id" small item-text="description"
                    item-value="payment_method_type_id" :items="pmtRet" label="Forma de pago" :rules="finAccountRule"
                    @change="cambioPmt()"></v-select>
                </v-col>

                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field v-model="datosPago.effective_date" label="Fecha" type="date" small
                    :rules="[validarEffectiveDate]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field v-model="datosPago.payment_ref_num" label="Referencia" type="text" small
                    :rules="[validarRefPago]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-text-field v-model="datosPago.monto_base" label="Monto base" type="text" small></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-text-field v-model="datosPago.pct_retencion" label="Porcentaje de retención" type="text"
                    small></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-text-field v-model="datosPago.monto" label="Monto pagado" type="text" small
                    :rules="[validarMontoPago]"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*campos obligatorios</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" small text @click="dialogRetencion = false" class="px-4 mb-4">
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" solid small class="white--text mb-4" @click="ingresarRetencion()">
            <v-icon>mdi-content-save</v-icon>Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FIN DIALOGO INGRESO DE RETENCION -->

    <!-- DIALOGO DE INGRESO DE CRUCE -->
    <v-dialog v-model="dialogCruce" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Cruzar con N/C</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formCruce">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="12" md="12">
                  <v-autocomplete v-model="notaCreditoAplicar" :items="notasCreditoPend" item-value="invoice_id"
                    item-text="invoice_number" dense label="Documento" @change="setMonto">
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title v-html="item.codigo_establecimiento +
                          '-' +
                          item.codigo_punto_emision +
                          '-' +
                          item.invoice_number
                          "></v-list-item-title>
                        <v-list-item-subtitle v-html="`${item.saldo} - ${item.invoice_date.slice(0, 10)}`
                          "></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-text-field v-model="montoNcAplicar" label="Monto pagado" type="text" small
                    :rules="[validarNcAplicado]"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*campos obligatorios</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" small text @click="dialogCruce = false" class="px-4 mb-4">
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" solid small class="white--text mb-4" @click="ingresarCruce()">
            <v-icon>mdi-content-save</v-icon>Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fin dialogo cruce-->


     <!-- DIALOGO DE SELECCION DE ITEMS PARA NC PARCIAL  -->
     <v-dialog v-model="dialogItemsNc" persistent max-width="800px">
      <v-card>
        <v-toolbar flat color="blue" height="40" dark>
          <span class="headline">Seleccione los items para incluir en la nota de crédito</span>
          <v-spacer></v-spacer>
          <v-btn class="px-4" @click="dialogItemsNc=!dialogItemsNc" small icon><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form ref="formSelItems">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="12" md="12">
                  <v-data-table  :items="itemsFactura" :headers="headItemsNc" hide-default-footer show-select v-model="itemsNcSel" item-key="secId" 
                  
                  > 
                  <template v-slot:footer >
                    <v-col cols="12" style="text-align:right"><h2>Total nota crédito: {{ sumaTotalNc }}</h2></v-col>
                    
                  </template>
                  </v-data-table>
                </v-col>                 
              </v-row>
            </v-form>
          </v-container>
          
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" solid small class="white--text mb-4" @click="generarNotaCreditoParcial()">
            <v-icon>mdi-content-save</v-icon>Crear Nota de crédito
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" small text @click="dialogItemsNc = false" class="px-4 mb-4">
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fin dialogo cruce-->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import AccountingEntries from "../general/AccountingEntries";
import Vue from "vue";

export default {
  name: "InvoiceFormComponent",
  components: {
    AccountingEntries,
  },
  props: {
    invoiceId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    cabecera: {      
      codigo_establecimiento: null,
      codigo_punto_emision: null,
      invoice_id: null,
      invoice_number: null,
      tipo_factura: null,
      invoice_date: "",
      participante: "",
      estado: "",
      cotizacionFa: "",
    },

    itemsEstado: [
      { estadoId: "INVOICE_READY", estadoNombre: "Listo" },
      { estadoId: "INVOICE_PAID", estadoNombre: "Pagado" },
      
    ],
    itemsFactura: [],
    totalesFactura: [],
    headItems: [
      { text: "Opciones", value: "opcion", align: "center", width: 50 },
      { text: "No.", value: "secId", align: "start" },
      { text: 'Tipo item', value: 'invoiceItemTypeId', align: "start" },
      { text: "Descripcion", value: "description", align: "start" },
      { text: "Cantidad", value: "cantidad", align: "end" },
      { text: "Precio", value: "valorUnitario", align: "end" },
      { text: "%IVA", value: "pct_iva", align: "end" },
      { text: "IVA", value: "valor_iva", align: "end" },
      { text: "Total", value: "total", align: "end" },
    ],

    headItemsNc: [
      { text: "No.", value: "secId", align: "start" },
      { text: "Descripcion", value: "description", align: "start" },
      { text: "Cantidad", value: "cantidad", align: "end" },
      { text: "Precio", value: "valorUnitario", align: "end" },
      { text: "%IVA", value: "pct_iva", align: "end" },
      { text: "IVA", value: "valor_iva", align: "end" },
      { text: "Descuento", value: "descuento", align: "end" },
      { text: "Total", value: "total_con_desc", align: "end" },
    ],
    
    headPagos: [
      { text: "Opciones", value: "opcion", align: "center", width: 30 },
      { text: "Codigo", value: "payment_id", align: "center", width: 50 },
      {
        text: "Tipo",
        value: "payment_method_type",
        align: "center",
        width: 150,
      },
      { text: "Fecha", value: "effective_date", align: "start" },
      { text: "Monto", value: "amount_applied", align: "start" },
    ],
    pagos: [],
    total_pagado: "",
    solChequeDev: false,
    datosDevCheque: { refCosto: "", ref1: "", fin_account_id: "" },

    resRefCheque: "",

    refChequeEc: "",
    valorChequeEc: "",
    refCostoEc: "",
    costoChequeEc: "",

    currentPage: 1,
    pageCount: 1,
    itemsPerPage: 20,

    searchNumber: "",
    searchFechaInv: "",
    searchEstado: "",
    searchCliente: "",
    cuentasBancarias: [],
    finAccountRule: [(v) => !!v || "El campo es obligatorio"],
    refRule: (v) => !!v || "El campo es requerido",
    required: [
            v => !!v || 'El campo es requerido'
        ],
    requiredMin: [
            v => !!v || 'El campo es requerido',
            v => !!v && v > 0 || 'Mayor a cero',
        ],
    existe_pago_cheque: false,
    overlay: false,
    datosPago: {
      payment_type_id: "CUSTOMER_PAYMENT",
      payment_ref_num: "",
      effective_date: new Date().toISOString().slice(0, 10),
      monto: 0,
      fin_account_id: "",
      payment_method_id: "",
      payment_method_type_id: "",
      pct_retencion: 0,
      monto_base: 0,
      pagado: 0,
    },
    tiposTarjeta: [],
    dialogPago: false,
    paymentMethodTypes: [
      { payment_method_type_id: "CASH", description: "Efectivo" },
      { payment_method_type_id: "EFT_ACCOUNT", description: "Transferencia" },
      { payment_method_type_id: "PERSONAL_CHECK", description: "Cheque" },
      {
        payment_method_type_id: "CREDIT_CARD",
        description: "Tarjeta de crédito",
      },
      {
        payment_method_type_id: "DEBIT_CARD",
        description: "Tarjeta de débito",
      },
      {
        payment_method_type_id: "CREDIT_CARD_DIF",
        description: "Tarjeta de crédito diferido",
      },
      {
        payment_method_type_id: "CREDIT_CARD_DIF_INT",
        description: "Tarjeta de crédito diferido sin intereses",
      },
      {
        payment_method_type_id: "BAJA_CARTERA_CC",
        description: "Baja de cartera cliente",
      },
      {
        payment_method_type_id: "PTMO_EMPLEADO",
        description: "Descuento del rol del empleado",
      },
      {
        payment_method_type_id: "CC_FARMACIA",
        description: "Cuenta por cobrar a farmacia",
      },
    ],
    pmtRet: [
      {
        payment_method_type_id: "RETENCION_IVA",
        description: "Retención de IVA",
      },
      {
        payment_method_type_id: "RETENCION_IR",
        description: "Retención de renta",
      },
    ],
    menu1: false,
    menuPagos: [
      { title: "Pago de cliente" },
      { title: "Retención" },
      { title: "Cruce con NC" },
    ],
    asientos: [],
    panel: "",
    dialogRetencion: false,
    dialogCruce: false,
    dialogItemsNc: false,
    notaCreditoAplicar: "",
    montoNcAplicar: 0,
    notasCreditoPend: [],
    itemsNcSel: [],
    totalSelNc: 0,
    noEdit: false,
    taxTypes:[],
    tiposItems: [],
    btnGuardarLoading:false
    
  }),
  computed: {
    ...mapState("master", [
      "loadingTable",
      "user",
      "tenantId",
      "paramAlertQuestion",
    ]),
    ...mapGetters("access", [
      "puedoSolicitarReembolsoCliente",
      "puedoBajaCarteraCliente",
    ]),

    sumaTotalNc() {
      let itemsNc = [];
      let totalNc = 0;

      for(let i=0; i<this.itemsNcSel.length; i++) {
        itemsNc.push(this.itemsNcSel[i].secId);
        totalNc += parseFloat(this.itemsNcSel[i].total_con_desc)
      }
      return totalNc
    },

    //validaciones cheque
    refExiste() {
      if (this.valorChequeEc == 0 || this.valorChequeEc == "")
        return "No se encontró la referencia";
      else return true;
    },

    validarRefCosto() {
      if (this.datosDevCheque.refCosto == "") {
        return "Ingrese la referencia";
      } else {
        return true;
      }
    },

    ///validaciones pagos
    validarRefPago() {
      if (this.datosPago.payment_method_type_id != "CASH") {
        if (this.datosPago.payment_ref_num == "") {
          return "Ingrese la referencia";
        } else {
          return true;
        }
      }
    },

    validarNcAplicado() {
      if (this.montoNcAplicar == "" || this.montoNcAplicar == 0) {
        return "Ingrese el monto del pago";
      } else {
        return true;
      }
    },
    
    validarMontoPago() {
      if (this.datosPago.monto == "" || this.datosPago.monto == 0) {
        return "Ingrese el monto del pago";
      } else {
        return true;
      }
    },
    validarEffectiveDate() {
      if (this.datosPago.effective_date == "") {
        return "Ingrese una fecha válida";
      } else {
        return true;
      }
    },
    validarCuentaBancaria() {
      if (this.datosPago.fin_account_id == "") {
        return "Seleccione una cuenta";
      } else {
        return true;
      }
    },
    validarTipoTarjeta() {
      if (this.datosPago.payment_method_id == "") {
        return "Seleccione una tarjeta";
      } else {
        return true;
      }
    },
    getUrlRide() {
      if (this.cabecera.clave_acceso != "") {
        return (
          "https://sufactura.ec/descargar-ride?claveAcceso=" +
          this.cabecera.clave_acceso
        );
      }
      return "#";
    },
    getUrlXml() {
      if (this.cabecera.clave_acceso != "") {
        return (
          "https://sufactura.ec/descargar-xml2?claveAcceso=" +
          this.cabecera.clave_acceso
        );
      }
      return "#";
    },
    getMenuComprobante() {
      let menu = [
        {
          title: "Descargar PDF",
          icon: "mdi-file-pdf",
          accion: this.getUrlRide,
        },
        { title: "Descargar XML", icon: "mdi-xml", accion: this.getUrlXml },
        //{ title: "Descargar zip", icon: "mdi-folder-zip", accion: "" }
      ];
      return menu;
    },

    getMenuNotaCredito() {
      let menu = [
        {
          title: "Devolución total",
          icon: "mdi-cash-refund",
          accion: "crearNotaCredito",
        },
        { title: "Devolución parcial", icon: "mdi-cash-refund", accion: "crearNotaCreditoParcial" },
        
      ];
      return menu;
    },
    tiposTarjetaMetodo() {
      let tt = [];
      for (let i = 0; i < this.tiposTarjeta.length; i++) {
        if (
          this.tiposTarjeta[i].payment_method_type_id ==
          this.datosPago.payment_method_type_id
        ) {
          tt.push(this.tiposTarjeta[i]);
        }
      }
      return tt;
    },
    montoRetencion() {
      if (
        this.datosPago.payment_method_type_id == "RETENCION_IR" ||
        this.datosPago.payment_method_type_id == "RETENCION_IVA"
      ) {
        return (
          (this.datosPago.pct_retencion * this.datosPago.monto_base) /
          100
        ).toFixed(2);
      }
    },
    formaPagoRet() {
      if (this.datosPago.payment_method_type_id == "RETENCION_IVA") {
        return this.totalesFactura.totalIVA;
      }

      if (this.datosPago.payment_method_type_id == "RETENCION_IR") {
        return (
          parseFloat(this.totalesFactura.subTotalConIva) +
          parseFloat(this.totalesFactura.subTotalSinIva)
        ).toFixed(2);
      }
    },
    formasPagoPermiso() {
      return this.paymentMethodTypes.filter((item) => {
        if (item.payment_method_type_id == "BAJA_CARTERA_CC") {
          return this.puedoBajaCarteraCliente;
        } else if (item.payment_method_type_id == "PTMO_EMPLEADO") {
          return this.puedoBajaCarteraCliente;
        } else if (item.payment_method_type_id == "CC_FARMACIA") {
          return this.puedoBajaCarteraCliente;
        } else {
          return true;
        }
      });
    },

    
    
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi","alertNotification"]),
    
    handleFunctionCall(function_name) {
        this[function_name]()
    },

    //cargar datos de factura
    formInvoice(invid) {
      this.overlay = true;
      this.setUrl("invoice/" + invid);
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.cabecera = res.data.cabecera;
          this.itemsFactura = res.data.itemsFactura;
          this.totalesFactura = res.data.totales;
          this.pagos = res.data.pagos;
          this.total_pagado = res.data.total_pagado;
          this.existe_pago_cheque = res.data.existe_pago_cheque;
          this.asientos = res.data.asientos;
          this.tiposTarjeta = res.data.tipos_tarjeta;
          this.notasCreditoPend = res.data.notas_credito;
          this.taxTypes = res.data.tipos_imp;
          this.tiposItems = res.data.tipos_items;
          this.overlay = false;

          let titulo =
            (this.cabecera.tipo_factura != null
              ? this.cabecera.tipo_factura + " #"
              : "") +
            (this.cabecera.codigo_establecimiento != null
              ? this.cabecera.codigo_establecimiento + "-"
              : "") +
            (this.cabecera.codigo_punto_emision != null
              ? this.cabecera.codigo_punto_emision + "-"
              : "") +
            (this.cabecera.invoice_number != null
              ? this.cabecera.invoice_number
              : "");
            this.paymentMethodTypes = res.data.formas_pago;

          this.setTitleToolbar(titulo);
        })
        .then(() => { });
    },

    cargarCuentasBancarias() {
      this.setUrl("cuentas-bancarias");
      this.requestApi({
        method: "GET",
        data: {},
      }).then((res) => {
        this.cuentasBancarias = res.data._embedded.cuentas_bancarias;
      });
    },

    //abrir formulario de solicitud de devolucion de cheque
    solicitarDevCheque() {
      this.datosDevCheque = { refCosto: "", ref1: "", fin_account_id: "" };
      this.refChequeEc = "";
      this.valorChequeEc = "";
      this.refCostoEc = "";
      this.costoChequeEc = "";
      this.solChequeDev = true;
    },
    //enviar solicitud de devolucion de cheque
    enviarSolicitudCheque() {
      if (!this.$refs.formSolChequeDev.validate()) {
        return false;
      }

      this.setUrl("nota-debito-cheque");
      this.requestApi({
        method: "POST",
        data: {
          ref1: this.refChequeEc,
          ref2: this.refCostoEc,
          party_id: this.cabecera.party_id,
          invoice_id_from: this.cabecera.invoice_id,
          fin_account_id: this.datosDevCheque.fin_account_id,
        },
      }).then((res) => {
        this.solChequeDev = false;

        this.alertNotification({
          param: {
            html: "Nota de debito generada exitosamente",
            timer: 5000,
          },
        });
      });
    },
    //busca la referencia en el libro bancos(banco pichincha)
    buscarRefBanco(codigo, doc) {
      this.setUrl("estado-cuenta");
      this.requestApi({
        method: "POST",
        data: {
          codigo: codigo,
          documento: doc,
        },
      }).then((res) => {
        if (codigo == "001316") {
          if (res.data.concil_estado_cuenta_id == "") {
            this.resRefCheque = "mdi-close-circle-outline";
            this.refChequeEc = "";
            this.valorChequeEc = "";
          } else {
            this.resRefCheque = "mdi-check-circle-outline";
            this.refChequeEc = res.data.concil_estado_cuenta_id;
            this.valorChequeEc = res.data.monto;
          }
        }
        if (codigo == "098426") {
          this.refCostoEc = res.data.concil_estado_cuenta_id;
          this.costoChequeEc = res.data.monto;
        }
      });
    },
    //ingresar pago
    ingresarPago() {
      if (!this.$refs.formPago.validate()) {
        return false;
      }
      this.btnGuardarLoading=true;
      this.setUrl("pago-recibido");
      this.requestApi({
        method: "POST",
        data: {
          payment_type_id: this.datosPago.payment_type_id,
          payment_method_type_id: this.datosPago.payment_method_type_id,
          payment_ref_num: this.datosPago.payment_ref_num,
          amount: this.datosPago.monto,
          comments: this.datosPago.comentario,
          effective_date: this.datosPago.effective_date,
          payment_method_id: this.datosPago.payment_method_id,
          invoice_id: this.cabecera.invoice_id,
          fin_account_id: this.datosPago.fin_account_id,
          party_id_from: this.cabecera.party_id,
          tipoTran: "ingresarPago",
        },
      }).then((res) => {
        this.dialogPago = false;
        this.formInvoice(this.$route.params.invoiceId);
        console.log(res.data);
        this.btnGuardarLoading=false;
        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
      }).catch(()=>{
        this.btnGuardarLoading=false;
      })
    },
    //abre formulario de pago
    abrirFormPago() {
      this.datosPago = {
        payment_type_id: "CUSTOMER_PAYMENT",
        payment_ref_num: "",
        effective_date: new Date().toISOString().slice(0, 10),
        monto: 0,
        fin_account_id: "",
        payment_method_id: "",
        payment_method_type_id: "",
      };
      this.dialogPago = true;
    },
    ingPago(idx) {
      if (idx == 0) this.abrirFormPago();
      if (idx == 1) this.abrirFormRetencion();
      if (idx == 2) this.abrirFormCruce();
    },

    //borra un pago aplicado
    borrarAplicacionPago(pagoApp) {
      Vue.swal({
        html: "Está seguro de eliminar este pago ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("pago-recibido");
          this.requestApi({
            method: "DELETE",
            data: {
              payment_application_id: pagoApp.payment_application_id,
              payment_id: pagoApp.payment_id,
            },
          }).then((res) => {
            this.dialogPago = false;
            this.formInvoice(this.$route.params.invoiceId);
            this.alertNotification({
              param: {
                html: res.data.res.msg,
              },
            });
          });
        }
      });
    },

    //
    cambioPmt() {
      this.datosPago.payment_method_id = "";
      this.datosPago.fin_account_id = "";
    },

    //crea un reembolso a cliente desde una nota de crédito
    solicitarReembolso() {
      Vue.swal({
        html: "Está seguro de solicitar el reembolso a este cliente?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crear-reembolso");
          this.requestApi({
            method: "POST",
            data: {
              invoice_id: this.cabecera.invoice_id,
              monto: (this.totalesFactura.total - this.total_pagado).toFixed(2),
            },
          }).then((res) => {
            this.cargarPago();
            this.alertNotification({
              param: {
                html: res.data.detail.msg,
              },
            });
          });
        }
      });
    },

    //abre formulario de retencion
    abrirFormRetencion() {
      this.datosPago = {
        payment_type_id: "RETENCION",
        payment_ref_num: "",
        effective_date: new Date().toISOString().slice(0, 10),
        monto: 0,
        fin_account_id: "",
        payment_method_id: "",
        payment_method_type_id: "",
        monto_base: 0,
        pct_retencion: 0,
      };
      this.dialogRetencion = true;
    },
    //ingresar pago
    ingresarRetencion() {
      if (!this.$refs.formRetencion.validate()) {
        return false;
      }

      this.setUrl("pago-recibido");
      this.requestApi({
        method: "POST",
        data: {
          payment_type_id: this.datosPago.payment_type_id,
          payment_method_type_id: this.datosPago.payment_method_type_id,
          payment_ref_num: this.datosPago.payment_ref_num,
          amount: this.datosPago.monto,
          comments: this.datosPago.comentario,
          effective_date: this.datosPago.effective_date,
          payment_method_id: this.datosPago.payment_method_id,
          invoice_id: this.cabecera.invoice_id,
          fin_account_id: this.datosPago.fin_account_id,
          party_id_from: this.cabecera.party_id,
          monto_base: this.datosPago.monto_base,
          pct_retencion: this.datosPago.pct_retencion,
          tipoTran: "ingresarPago",
        },
      }).then((res) => {
        this.dialogRetencion = false;
        this.formInvoice(this.$route.params.invoiceId);

        this.alertNotification({
          param: {
            html: res.data.res.msg,
          },
        });
      });
    },

    //abre formulario de retencion
    abrirFormCruce() {
      this.datosPago = {
        payment_type_id: "CRUCE_NC",
        payment_ref_num: "",
        effective_date: new Date().toISOString().slice(0, 10),
        monto: 0,
        payment_method_id: "",
        payment_method_type_id: "CRUCE_NCV",
        pagado: 0,
      };
      this.notaCreditoAplicar = "";
      this.dialogCruce = true;
    },
    //ingresar pago
    ingresarCruce() {
      if (!this.$refs.formCruce.validate()) {
        return false;
      }

      this.setUrl("pago-recibido");
      this.requestApi({
        method: "POST",
        data: {
          payment_type_id: this.datosPago.payment_type_id,
          payment_method_type_id: this.datosPago.payment_method_type_id,
          payment_ref_num: this.datosPago.payment_ref_num,
          amount: this.montoNcAplicar,
          comments: this.datosPago.comentario,
          effective_date: this.datosPago.effective_date,
          payment_method_id: this.datosPago.payment_method_id,
          invoice_id: this.cabecera.invoice_id,
          nota_credito_id: this.notaCreditoAplicar,
          party_id_from: this.cabecera.party_id,
          monto_base: this.datosPago.monto_base,
          tipoTran: "ingresarCruce",
        },
      }).then((res) => {
        this.dialogCruce = false;
        this.formInvoice(this.$route.params.invoiceId);
        
      });
    },

    setMonto() {
      for (let i = 0; i < this.notasCreditoPend.length; i++) {
        if (this.notasCreditoPend[i].invoice_id == this.notaCreditoAplicar) {
          if (
            (this.totalesFactura.total - this.total_pagado).toFixed(2) >
            this.notasCreditoPend[i].saldo
          ) {
            this.montoNcAplicar = this.notasCreditoPend[i].saldo;
          } else {
            console.log("set monto 3" + this.datosPago.pagado);
            this.montoNcAplicar = (
              this.totalesFactura.total - this.total_pagado
            ).toFixed(2);
          }
        }
      }
    },
    crearNotaCredito() {
      this.setUrl("invoice");
      this.requestApi({
        method: "POST",
        data: {
          invoice_id: this.cabecera.invoice_id,
          typeAction: "crearNotaCreditoAnulacion",
        },
      }).then((res) => {
        
        //this.formInvoice(this.$route.params.invoiceId);
        console.log(res);
        this.alertNotification({
          param: {
            html: res.data.msg,
          },
        });
        if(res.data.invoice_id != "") {
          
          this.$router.push({
              path: "/factura-venta/"+res.data.invoice_id
            });
            this.$router.go();
        }
      });
    },

    crearNotaCreditoParcial() {
      //alert('nota credito parcial')
      this.itemsNcSel = []
      this.dialogItemsNc=true;
    },

    generarNotaCreditoParcial() {
      //console.log(this.itemsNcSel)
      let itemsNc = [];      

      
      for(let i=0; i<this.itemsNcSel.length; i++) {
        itemsNc.push(this.itemsNcSel[i].secId);      
      }
      
      this.setUrl("invoice");
      this.requestApi({
        method: "POST",
        data: {
          invoice_id: this.cabecera.invoice_id,
          typeAction: "crearNotaCreditoParcial",
          items: itemsNc
        },
      }).then((res) => {
        
        //this.formInvoice(this.$route.params.invoiceId);
        console.log(res.data);
        this.alertNotification({
          param: {
            html: res.data.msg,
          },
        });
        if(res.data.invoice_id != "") {
          
          this.$router.push({
              path: "/factura-venta/"+res.data.invoice_id
            });
            this.$router.go();
        }
      });
    },
    calculateTotal() {
      alert('xxx')
    },


     storeInvoceItem(index) {

            this.setUrl('invoice')

            const {
                invoice_item_type_id,
                description,
                price,
                quantity,
                secId,
                tax,
                tax_authority_rate_seq_id,
                product_category_id,
                tax_percentage
            } = this.selectedInvoiceItems[index]

            this.requestApi({
                method: 'POST',
                data: {
                    typeAction: 'storeItemInvoice',
                    invoice_id: this.invoice.invoice_id,
                    invoice_item_type_id,
                    description,
                    price,
                    quantity,
                    secId,
                    tax,
                    tax_authority_rate_seq_id,
                    product_category_id,
                    tax_authority_rate_seq_id,
                    tax_percentage
                }
            }).then(res => {
                console.log(res)
                this.alertNotification({ param: { html: res.data.res.msg } })
                this.formInvoice(this.$route.params.invoiceId);

            })

        },

        deleteInvoiceItem(item) {

            Vue.swal({
                html: "Está seguro de eliminar el mitem de la factura?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {

                    if (typeof item.secId != 'undefined') {
                        this.setUrl('invoice')                        
                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                invoice_id: item.invoiceId,
                                secId: item.secId,
                                typeDelete: 'deleteInvoiceItem',
                            }
                        }).then(res => {
                            this.alertNotification({ param: { html: res.data.detail.msg } })
                            this.formInvoice(this.$route.params.invoiceId);
                        })

                    }

                    let index = this.selectedInvoiceItems.indexOf(item)
                    this.selectedInvoiceItems.splice(index, 1)
                    this.calculateTotal()



                }
            })

        },

        completarInvoice() {          
          this.setUrl('invoice')
          this.requestApi({
              method: 'POST',
              data: {
                  typeAction: 'completarFacturaVenta',
                  invoice_id: this.cabecera.invoice_id,                 
              }
          }).then(res => {
              console.log(res)
              this.alertNotification({ param: { html: res.data.msg } })
              this.formInvoice(this.$route.params.invoiceId);

          })
        }

    
  },
  

  mounted() {
    this.cargarCuentasBancarias();
    this.formInvoice(this.$route.params.invoiceId);
  },

  watch: {
    montoRetencion(newVal) {
      //console.log("cambiando monto_base");
      this.datosPago.monto = newVal;
    },
    formaPagoRet(newVal) {
      //console.log("cmabiando fp retencion");
      this.datosPago.monto_base = newVal;
    },
  },
};
</script>
